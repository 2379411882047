<template>
  <div id="wrapper" class="pricing">
    <!-- Navigation -->
    <NavBar fixedNav="true" />

    <div class="page">
      <div class="row" v-if="step === 0">
        <div class="col-12 col-lg-1"></div>
        <div class="col-12 col-lg-10">
          <div>
            <h3 class="text-center pt-3">
              <span v-if="freeTrial">
                Choose a plan to start your 7-day free trial
              </span>
              <span v-else>
                Choose the plan that's right for you
              </span>
            </h3>

            <div class="row">
              <div class="col-12 col-md-4 col-lg-4 plan-wrapper"
                  v-for="plan in plans"
                  :key="plan.id"
                  :class="plan.class_name">
                <div class="plan"
                    :class="plan.id === selected.id ?
                      'selected' : ''">
                  <div class="header">
                    <h3 class="plan-name text-center">{{ plan.name }}</h3>
                  </div>

                  <div class="details text-center">
                    <div
                      class="price"
                      v-if="plan.class_name !== 'school-district'">
                      <p class="mb-0">
                        <b>${{ plan.price }}</b>
                        <span>/ {{ plan.billing_interval }}</span>
                      </p>
                    </div>
                    <div class="price mb-5" v-else>
                      <p class="mb-0"></p>
                    </div>

                    <ul>
                      <li
                        v-for="(feature, index) in plan.features"
                        :key="index">
                        <div v-if="feature.web_display">
                          <span v-html="feature.web_icon"></span>
                          {{ feature.web_display }}
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="button-wrapper text-center"
                       v-if="plan.class_name !== 'school-district'">
                    <b-button class="btn btn-h3-small"
                              variant="primary"
                              @click="selectPlan(plan)">
                      Select this Plan
                    </b-button>
                  </div>

                  <div class="button-wrapper text-center" v-else>
                    <b-button class="btn btn-h3-blue btn-h3-small"
                              @click="openContactForm">
                      Contact Us
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-1"></div>
      </div>

      <div class="row pt-5 pb-5 step-1" v-if="step === 1">
        <div class="col-12 col-lg-2 my-auto text-center">
          <i class="far fa-arrow-alt-circle-left fa-4x previous"
             @click.prevent="prev()">
          </i>
        </div>
        <div class="col-12 col-lg-8">
          <h4 class="text-center" v-if="!freeTrial">
            Subscribe to {{ selected.name }}
          </h4>
          <h4 class="text-center" v-else>
            Subscribe to {{ selected.name }} Trial
          </h4>
          <p class="text-center small mb-0">Step 1 of 2</p>
          <p class="text-center medium">Create your account.</p>

          <div class="text-center">
            <!-- Social Medida -->
            <social-media-auth />

            <p>OR</p>

            <!-- Registration form -->
            <b-form @submit="createAccount">
              <b-input-group prepend="User Type">
                <b-form-select class="user-type"
                               id="usertype"
                               v-model="form.usertype"
                               :state="!$v.form.usertype.$error"
                               :options="usertypes"
                               @input="$v.form.usertype.$touch()"
                ></b-form-select>
                <b-form-invalid-feedback class="single-line" id="usertype">
                  <span v-if="!$v.form.usertype.required">
                    User type is required.
                  </span>
                </b-form-invalid-feedback>
              </b-input-group>

              <b-input-group prepend="Nickname" v-if="form.usertype === 2">
                <b-form-input id="nickname"
                              autocomplete="off"
                              type="text"
                              v-model="form.nickname"
                              :state="!$v.form.nickname.$error"
                              @input="$v.form.nickname.$touch()"
                ></b-form-input>
                <b-form-invalid-feedback class="single-line" id="nickname">
                  <span v-if="!$v.form.nickname.required">
                    Nickname is required.
                  </span>
                </b-form-invalid-feedback>
              </b-input-group>

              <b-form-row>
                <b-col sm>
                  <b-input-group prepend="First Name"
                                  v-if="form.usertype != 2">
                    <span class="tooltiptext" v-if="showTooltip.fname">
                      Enter your First name. (Example: Roy)
                    </span>
                    <b-form-input id="fname"
                                  autocomplete="off"
                                  type="text"
                                  v-model="form.fname"
                                  :state="!$v.form.fname.$error"
                                  @input="$v.form.fname.$touch()"
                                  @focus="showTooltip.fname = true"
                                  @focusout="showTooltip.fname = false"
                    ></b-form-input>
                    <b-form-invalid-feedback id="fname">
                      <span v-if="!$v.form.fname.required">
                        First name is required.
                      </span>
                      <span v-if="!$v.form.fname.minLength">
                        Minimum length must be 2 characters.
                      </span>
                      <span v-if="!$v.form.fname.name">
                        Contains invalid characters.
                      </span>
                      <span v-if="!$v.form.fname.safeFirstName">
                        First Name not allowed.
                      </span>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-col>

                <b-col sm>
                  <b-input-group prepend="Last Name"
                                  v-if="form.usertype != 2">
                    <span class="tooltiptext" v-if="showTooltip.lname">
                      Enter your Last name. (Example: Scott)
                    </span>
                    <b-form-input id="lname"
                                  autocomplete="off"
                                  type="text"
                                  v-model="form.lname"
                                  :state="!$v.form.lname.$error"
                                  @input="$v.form.lname.$touch()"
                                  @focus="showTooltip.lname = true"
                                  @focusout="showTooltip.lname = false"
                    ></b-form-input>
                    <b-form-invalid-feedback id="lname">
                      <span v-if="!$v.form.lname.required">
                        Last name is required.
                      </span>
                      <span v-if="!$v.form.lname.minLength">
                        Minimum length must be 2 characters.
                      </span>
                      <span v-if="!$v.form.lname.name">
                        Contains invalid characters.
                      </span>
                      <span v-if="!$v.form.lname.safeLastName">
                        Last name not allowed.
                      </span>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm>
                  <b-input-group prepend="Email">
                    <span class="tooltiptext" v-if="showTooltip.email">
                      Enter your email here! Use this everytime you log in.
                    </span>
                    <b-form-input id="email"
                                  autocomplete="off"
                                  type="email"
                                  v-model="form.email"
                                  :state="!$v.form.email.$error"
                                  @input="$v.form.email.$touch()"
                                  @focus="showTooltip.email = true"
                                  @focusout="showTooltip.email = false"
                    ></b-form-input>
                    <b-form-invalid-feedback id="email">
                      <span v-if="!$v.form.email.required">
                        Email is required.
                      </span>
                      <span v-if="!$v.form.email.email">
                        Please provide a valid email.
                      </span>
                      <span v-if="!$v.form.email.uniqueEmail">
                        Email is already registered.
                      </span>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-col>
                <b-col sm>
                  <b-input-group prepend="Password">
                  <span class="tooltiptext" v-if="showTooltip.password">
                    Create a password you will use to login. Make sure to
                    remember this to log in the next time you visit.
                  </span>
                  <b-form-input id="password"
                                type="password"
                                v-model="form.password"
                                :state="!$v.form.password.$error"
                                @input="$v.form.password.$touch()"
                                @focus="showTooltip.password = true"
                                @focusout="showTooltip.password = false"
                  ></b-form-input>
                  <b-form-invalid-feedback id="password">
                    <span v-if="!$v.form.password.required">
                      Password is required.
                    </span>
                    <span v-if="!$v.form.password.minLength">
                      Minimum Length should be 5 characters.
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>
                </b-col>
              </b-form-row>

              <school-widget :classes = "schoolClass"
                             :prepend="true"
                             :allowAdd="true"
                             :state="!$v.form.school.$error"
                             @changed="updateSelectedSchool"
                             v-if="form.usertype === 3" />
            </b-form>
          </div>
        </div>

        <div class="col-12 col-lg-2 my-auto text-center">
          <i class="far fa-arrow-alt-circle-right fa-4x next"
              @click.prevent="createAccount()">
          </i>
        </div>
      </div>

      <div class="row pt-5 pb-5 step-2" v-if="step === 2">
        <div class="col-12 col-lg-2 my-auto text-center">
          <i class="far fa-arrow-alt-circle-left fa-4x next"
              @click.prevent="prev()">
          </i>
        </div>
        <div class="col-12 col-lg-8 text-center mx-auto">
          <h4 class="medium">Subscribe to {{ selected.name }}
            <span v-if="freeTrial"> Trial </span>
          </h4>
          <p class="small mb-0">Step 2 of 2</p>
          <p class="medium">Finish setting up your subscription.</p>
          <br>
          <p class="small" v-if="!freeTrial">
            Your subscription starts as <br> soon as you set up payment.
          </p>
          <p class="small" v-else>
            You will be charged as soon as your FREE TRIAL is consumed.
            <br> You can cancel anytime.
          </p>

          <ul class="payment-methods">
            <li @click="proceedToPayment">
              <div class="d-flex justify-content-between">
                <p class="small mb-0">Credit or Debit Card</p>
                <div class="cards">
                  <img class="img-fluid card"
                      src="../assets/images/cards/master-card.png"
                      alt="master-card" />
                  <img class="img-fluid card"
                      src="../assets/images/cards/visa.png"
                      alt="visa" />
                  <img class="img-fluid card"
                      src="../assets/images/cards/dinners-club.png"
                      alt="dinners" />
                  <img class="img-fluid card"
                      src="../assets/images/cards/jcb.png"
                      alt="jcb" />
                  <img class="img-fluid card"
                      src="../assets/images/cards/credit-card.png"
                      alt="credit-card" />
                  <i class="fa fa-angle-right next-chevron"
                      aria-hidden="true"></i>
                </div>
              </div>
            </li>
            <li>
              <div id="paypal-button-container"></div>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-2 my-auto text-center"></div>
      </div>
    </div>

    <!-- Contact Form -->
    <ContactButton visibleFooter="true"/>

    <!-- Modal -->
    <modals-container />

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.5"
             :width="70"
             :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  const ContactButton = () => import('@/components/shared/ContactButton.vue');
  const Loading = () => import('vue-loading-overlay');
  const NavBar = () =>  import('@/components/shared/Navbar.vue');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  const SchoolWidget = () => import('@/components/shared/SchoolWidget.vue');
  const SocialMediaAuth =
    () => import('@/components/shared/SocialMediaAuth.vue');
  const ContactForm =
    () => import('@/components/modals/SubscriptionContactForm');

  import _ from 'lodash';
  import { required, minLength, email, helpers } from
    'vuelidate/lib/validators';
  import ProfanityMixin from '../mixins/ProfanityMixin';
  import SubscriptionMixin from '@/mixins/SubscriptionMixin';
  import moment from 'moment-timezone';
  import { mapGetters } from 'vuex';

  const name = helpers.regex('name', /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/);

  export default {
    name   : 'Pricing',
    mixins : [
      ProfanityMixin,
      SubscriptionMixin,
    ],
    data() {
      return {
        isLoading : false,
        freeTrial : false,
        selected  : {
          id : null,
        },
        step : 0,
        form : {
          usertype : null,
          nickname : null,
          fname    : null,
          lname    : null,
          password : null,
          email    : null,
          school   : null,
        },
        showTooltip : {
          fname    : false,
          lname    : false,
          email    : false,
          password : false,
        },
        uniqueEmail   : true,
        safeNickname  : true,
        safeFirstName : true,
        safeLastName  : true,

        usertypes : [
          { text : 'Parent', value : 2 },
          { text : 'Educator', value : 3 },
        ],

        schoolClass : 'is-valid',
      }
    },
    computed : {
      ...mapGetters({
        plans : 'subscription/plans',
      }),
    },
    components : {
      ContactButton,
      Loading,
      NavBar,
      RainbowLoader,
      SocialMediaAuth,
      SchoolWidget,
    },
    watch : {
      'form.usertype'(value) {
        if (value)
          this.$v.$reset();
      },
      'form.nickname'(value) {
        if (value)
          this.checkProfanity('safeNickname', value);
        else
          this.safeNickname = true;
      },
      'form.fname'(value) {
        if (value)
          this.checkProfanity('safeFirstName', value);
        else
          this.safeFirstName = true;
      },
      'form.lname'(value) {
        if (value)
          this.checkProfanity('safeLastName', value);
        else
          this.safeLastName = true;
      },
      'form.email'() {
        this.uniqueEmail = true;
        this.checkEmail();
      },
      selected(value) {
        if (value) {
          localStorage.selectedSubPlan = { id : null };
          localStorage.selectedSubPlan = JSON.stringify(value);
        }
      },
      '$route.params.step'(value) {
        if (value)
          this.step = parseInt(value);
      },
    },
    methods : {

      /**
       * Select a Plan
       * @param planData
       */
      selectPlan(planData) {
        if (planData.id !== 4) {
          this.selected = planData;

          if (this.selected.id === 1)
            this.$router.push('/sign-up');
          else
            this.next();
        }
      },

      /**
       * Next Step
       */
      next() {
        this.$router.push(`/pricing/${this.step + 1}`);
      },

      /**
       * Previous Step
       */
      prev() {
        if (this.step === 1) {
          // Reset form value and school class
          this.form = {};
          this.schoolClass = 'is-valid';

          this.step = this.step - 1;
          this.$router.push('/pricing');
        } else {
          this.step = this.step - 1;
          this.$router.push(`/pricing/${this.step}`);
        }
        this.$v.$reset();
      },

      /**
       * Create User Account
       */
      createAccount() {
        if (this.validateForm()) {
          this.isLoading = true;

          const params = {
            email    : this.form.email,
            password : this.form.password,
            userType : this.form.usertype,
          };

          if (this.form.usertype === 2)
            params.nickname = this.form.nickname;
          
          if (this.form.usertype === 3) {
            params.firstname = this.form.fname;
            params.lastname = this.form.lname;
            params.schoolId = this.form.school;
            params.nickname = this.form.fname + ' ' + this.form.lname;
          }

          this.$http.post('auth/register', params)
            .then(async response => {
              if (response.status == 201) {
                localStorage.regPath = `/${this.$route.name}`;
                
                // Send Activation Email
                await this.$http.post('auth/sendActivationEmail', {
                  to : this.form.email,
                });

                // Auto Login
                await this.$store.dispatch('auth/login', {
                  email    : this.form.email,
                  password : this.form.password,
                });

                // Proceed to the next step
                this.next();
              }
            }).catch(() => {
              this.$notify({
                group : 'notif',
                type  : 'error',
                title : 'Failed',
                text  : 'Oops! Something went wrong!',
              });
            }).finally(() => {
              this.$analytics.fbq.event('registration', {
                'email'    : this.form.email,
                'page'     : 'pricing',
                'platform' : 'website',
              });
              this.isLoading = false;
            });
        }
      },

      /**
       * Extra Form Validation
       */
      validateForm() {
        this.$v.form.usertype.$touch();
        this.$v.form.email.$touch();
        this.$v.form.password.$touch();

        if (!this.form.usertype) {
          this.$v.form.fname.$touch();
          this.$v.form.lname.$touch();
        } else if (this.form.usertype === 2)
          this.$v.form.nickname.$touch();
        else if (this.form.usertype === 3) {
          this.$v.form.fname.$touch();
          this.$v.form.lname.$touch();
          this.$v.form.school.$touch();
        }

        if (this.$v.form.school.$error)
          this.schoolClass = 'is-invalid';
        else
          this.schoolClass = 'is-valid';

        if (this.$v.form.usertype.$error ||
          this.$v.form.nickname.$error ||
          this.$v.form.email.$error ||
          this.$v.form.password.$error ||
          this.$v.form.fname.$error ||
          this.$v.form.lname.$error ||
          this.$v.form.school.$error)
          return false;
        
        return true;
      },

      /**
       * Proceed to Setup Payment
       */
      proceedToPayment() {
        const data = {
          source        : 'pricing-page',
          planId        : this.selected.id,
          stripePriceId : this.selected.stripe_price_id,
          freeTrial     : this.freeTrial,
        };

        this.checkoutSession('create-subscription', data);
      },

      /**
       * Update Selected School
       * @param data
       */
      updateSelectedSchool(data) {
        if (data) {
          this.form.school = data;
          this.schoolClass = 'is-valid';
        } else {
          this.form.school = null;
          this.schoolClass = 'is-invalid';
        }
      },

      /**
       * Check if Email is registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true if email exists
          //   hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        });
      }, 500),

      /**
       * Open Contact Form
       */
      openContactForm() {
        this.$modal.show(ContactForm, {}, {
          height   : 'auto',
          adaptive : true,
        });
      },
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'pricing',
        'platform' : 'website',
      });

      // Get H3 Plans
      this.$store.dispatch('subscription/getPlans');
      // this.getPlans();

      // Route params redirection after successful social media login
      if (this.$route.params.step === undefined) {
        this.step = 0;
        localStorage.removeItem('selectedSubPlan');
      } else {
        this.step = parseInt(this.$route.params.step);
        if (localStorage.getItem('selectedSubPlan'))
          this.selected = JSON.parse(localStorage.getItem('selectedSubPlan'));
      }

      // Free Trial
      if (localStorage.getItem('enableFreeTrial') === 'true')
        this.freeTrial = true;
      else
        this.freeTrial = false;

    },
    updated() {
      if (this.step === 2) {
        const container = document.getElementById('paypal-button-container');

        if (!container.hasChildNodes()) {
          // PayPal Payment Method
          // eslint-disable-next-line no-undef
          paypal.Buttons({
            style : {
              layout  : 'vertical',
              color   : 'white',
              shape   : 'rect',
              label   : 'paypal',
              tagline : false,
            },

            createSubscription : function(data, actions) {
              const plan = JSON.parse(localStorage.getItem('selectedSubPlan'));
              const freeTrial = localStorage.getItem('enableFreeTrial');
              const planId = (freeTrial === 'true') ?
                plan.paypal_plan_id_trial : plan.paypal_plan_id;
              const startUTC = moment.utc().add(10, 'seconds').format();

              return actions.subscription.create({
                'plan_id'    : planId,
                'start_time' : startUTC,
              });
            },

            onApprove : function(data, actions) {
              localStorage.paypalSubscription = JSON.stringify(data);
              window.location.replace(
                process.env.VUE_APP_BASE_URL + '/dashbaord');
                
              // window.location.replace(
              //   process.env.VUE_APP_BASE_URL + '/browse/main');
            },
          }).render('#paypal-button-container');
        }
      }
    },
    validations() {
      if (this.form.usertype === 2) {
        return {
          form : {
            usertype : { required },
            nickname : { required },
            fname    : {},
            lname    : {},
            email    : {
              required,
              email,
              uniqueEmail() {
                return this.uniqueEmail;
              },
            },
            school   : {},
            password : {
              required,
              minLength : minLength(2),
            },
          },
        }
      }

      return {
        form : {
          usertype : { required },
          nickname : {},
          fname    : {
            required,
            name,
            minLength : minLength(2),
            safeFirstName() {
              return this.safeFirstName;
            },
          },
          lname : {
            required,
            name,
            minLength : minLength(2),
            safeLastName() {
              return this.safeLastName;
            },
          },
          email : {
            required,
            email,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          },
          school   : { required },
          password : {
            required,
            minLength : minLength(2),
          },
        },
      }
      
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/scss/components/pricing';
</style>